import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'

export const BlogPostTemplate = ({
                                     content,
                                     contentComponent,
                                     description,
                                     title,
                                     lastUpdate,
                                     image,
                                     helmet,
                                 }) => {
    const PostContent = contentComponent || Content

    return (
        <section className="container-fluid m-0 p-0">
            {helmet || ''}

            <header className="masthead" style={{backgroundImage: `url(${image})`}}>
                <div className="overlay"></div>
                <div className="row">
                    <div className="col-lg-8 col-md-10 mx-auto">
                        <div className="post-heading ">
                            <h1 className="px-3"> {title}</h1>
                            <h2 className="subheading px-3 font-weight-normal">{description}</h2>
                        </div>
                    </div>
                </div>
            </header>


            <article>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 p-4 text-justify">
                            <PostContent content={content}/>
                        </div>
                    </div>

                    <hr/>
                </div>
            </article>


        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    image: PropTypes.string,
    helmet: PropTypes.instanceOf(Helmet),
}

const BlogPost = ({data}) => {
    const {markdownRemark: post} = data

    return (
        <Layout>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={<Helmet  image={post.frontmatter.image}>
                        <title>{`${post.frontmatter.title} | Blog`}</title>
                        <meta property="twitter:image" content={post.frontmatter.image} />
                        <meta property="og:image" content={post.frontmatter.image} />
                     </Helmet>}
                title={post.frontmatter.title}
                lastUpdate={post.frontmatter.date}
                image={post.frontmatter.image}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
                              markdownRemark: PropTypes.object,
                          }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image
      }
    }
  }
`
